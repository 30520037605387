import React, { useState, useRef, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { ReactComponent as Notice } from 'svgs/notice.svg'
import { ReactComponent as Require } from 'svgs/require.svg'
import { ReactComponent as CircleCheck } from 'svgs/circle-check.svg'
import { ReactComponent as CircleAlert } from 'svgs/circle-alert.svg'
import Button from '../components/Button'
import ChangePasswordModal from '../components/ChangePasswordModal'
import ToastMessage from '../components/ToastMessage'
import Input from '../components/Input'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import useAccessToken from '../hooks/useAccessToken'
import { captureException } from '@sentry/react'
import ToastAlert from '../components/ToastAlert'
import SidebarLayout from '../components/SidebarLayout'

function SettingProfile() {
  const { logout, user, getAccessTokenSilently } = useAuth0()
  const avatarInputRef = useRef<HTMLInputElement | null>(null)

  const accessToken = useAccessToken()
  const [avatar, setAvatar] = useState(user?.picture || '')
  const [isOpen, setIsOpen] = useState(false)
  const [firstName, setFirstName] = useState(user?.given_name || '')
  const [lastName, setLastName] = useState(user?.family_name || '')

  useEffect(() => {
    if (avatarInputRef.current) {
      avatarInputRef.current.value = ''
    }
  }, [avatar])
  useEffect(() => {
    if (accessToken) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/_auth0_api/users/${user?.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(resp => {
          setFirstName(resp.data.given_name)
          setLastName(resp.data.family_name)
          setAvatar(resp.data.picture)
        })
        .catch(e => {
          captureException(e)
        })
    }
  }, [user, accessToken])
  const handleSave = async () => {
    try {
      const resp = await axios.patch(
        `${process.env.REACT_APP_API_URL}/_auth0_api/users/${user?.sub}`,
        {
          family_name: lastName,
          given_name: firstName
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      toast(
        <ToastMessage>
          <CircleCheck className="mr-[24px]" />
          <p>Successfully saved !</p>
        </ToastMessage>
      )
    } catch (e: any) {
      captureException(e)
      if (e.response?.data?.message) {
        toast(
          <ToastAlert>
            <p>{e.response.data.message}</p>
          </ToastAlert>
        )
      } else {
        toast(
          <ToastAlert>
            <p>unknown error</p>
          </ToastAlert>
        )
      }
    }
  }

  const handleResetEmail = async () => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth0/send-reset-email`,
        {
          email: user?.email || user?.name
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      toast(
        <ToastMessage>
          <CircleCheck className="mr-[24px]" />
          <p>{resp.data}</p>
        </ToastMessage>
      )
    } catch (e: any) {
      captureException(e)
      if (e.response?.data?.message) {
        toast(
          <ToastAlert>
            <p>{e.response.data.message}</p>
          </ToastAlert>
        )
      } else {
        toast(
          <ToastAlert>
            <p>unknown error</p>
          </ToastAlert>
        )
      }
    }
  }
  const handleSetAvatar = async (event: any) => {
    const data = new FormData()
    data.append('file', event.target.files[0])
    try {
      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/auth0/set-profile-picture`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setAvatar(resp.data)
      toast(
        <ToastMessage>
          <CircleCheck className="mr-[24px]" />
          <p>Profile picture set</p>
        </ToastMessage>
      )
    } catch (e: any) {
      captureException(e)
      if (e.response?.data?.message) {
        toast(
          <ToastAlert>
            <p>{e.response.data.message}</p>
          </ToastAlert>
        )
      } else {
        toast(
          <ToastAlert>
            <p>unknown error</p>
          </ToastAlert>
        )
      }
    }
  }

  const handleRemoveAvatar = async () => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth0/remove-profile-picture`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      setAvatar(resp.data)
      toast(
        <ToastMessage>
          <CircleCheck className="mr-[24px]" />
          <p>Profile picture removed</p>
        </ToastMessage>
      )
    } catch (e: any) {
      captureException(e)
      if (e.response?.data?.message) {
        toast(
          <ToastAlert>
            <p>{e.response.data.message}</p>
          </ToastAlert>
        )
      } else {
        toast(
          <ToastAlert>
            <p>unknown error</p>
          </ToastAlert>
        )
      }
    }
  }

  return (
    <>
      <SidebarLayout>
        <div className="flex w-[80%] max-w-[1920px] flex-col justify-center">
          <div className="ml-[146px]">
            <h1 className="text-[27px] font-bold">Profile</h1>
            <p className="text-[16px]">This information is visible to you only.</p>
            <div className="mb-[56px] mt-[30px] flex flex-wrap items-center">
              <img
                src={avatar || '/images/no-photo-icon.png'}
                alt="avatar"
                className="mr-[22px] h-[51px] w-[51px] rounded-full"
              />
              <input
                id="avatar"
                type="file"
                name="avatar"
                accept="image/*"
                hidden
                onChange={handleSetAvatar}
                ref={avatarInputRef}
              />
              <Button
                classes="mr-[25px] w-[95px] h-[41px] rounded-[10px] xl:mb-0"
                buttonProps={{ type: 'button' }}
                onClick={() => document.getElementById('avatar')?.click()}>
                Change
              </Button>
              <div className="relative bottom-[14px] flex flex-col items-start">
                {/*<div className="flex items-center bg-[#F5F5F5]">*/}
                {/*  <Notice />*/}
                {/*  <p className="pl-1 pr-5 text-[10px]">*/}
                {/*    By default, the image will be replaced with initials if you remove it*/}
                {/*  </p>*/}
                {/*</div>*/}
                <Button
                  classes="mt-1 bg-transparent !text-black"
                  onClick={handleRemoveAvatar}
                  buttonProps={{ type: 'button' }}>
                  Remove
                </Button>
              </div>
            </div>
            <h2 className="text-[27px] font-bold" data-testid="details">
              My Details
            </h2>
            <div className="flex flex-col text-[18px] font-bold">
              <label className="flex">
                First name
                <span>
                  <Require />
                </span>
              </label>
              <Input
                type="text"
                classes="mt-[17px] rounded-[4px] border-2 border-[#E1E1E5] font-normal w-[57%]"
                inputProps={{ id: 'firstname', name: 'firstname' }}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="mt-[18px] flex flex-col text-[18px] font-bold">
              <label className="flex">
                Last name
                <span>
                  <Require />
                </span>
              </label>
              <Input
                type="text"
                classes="mt-[17px] rounded-[4px] border-2 border-[#E1E1E5] font-normal w-[57%]"
                inputProps={{ id: 'lastname' }}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                required
              />
            </div>
            <Button
              classes="text-[20px] block mt-[62px] bg-transparent !text-[#1769FF] underline font-bold"
              onClick={handleResetEmail}
              buttonProps={{ type: 'button' }}>
              Change Password
            </Button>
            <Button
              classes="text-[21px] mr-[266px] mt-[27px] w-[81px] h-[48px] rounded-[8px] float-right"
              buttonProps={{ form: 'profile-form' }}
              onClick={handleSave}>
              Save
            </Button>
          </div>
          {/*<ToastContainer*/}
          {/*  autoClose={5000}*/}
          {/*  hideProgressBar={true}*/}
          {/*  className="!w-fit"*/}
          {/*  toastClassName="flex !justify-start w-fit !min-h-0 h-[52px] !px-[24px] !py-[10px] border !rounded-[30px]"*/}
          {/*  bodyClassName="!flex-none !p-0 !mr-[24px]"*/}
          {/*/>*/}
          <ChangePasswordModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </div>
      </SidebarLayout>
    </>
  )
}

export default SettingProfile
