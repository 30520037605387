import { User } from '@auth0/auth0-react'
import axios from 'axios'
import apiClient from './configs/AxiosClient'

export const AuthAPI = {
  resetPasswordByEmail: async function (inputEmail: string) {
    const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`
    const response = await apiClient.post(url, {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: inputEmail,
      connection: 'Username-Password-Authentication'
    })
    return response.data
  },
  changePassword: async (newPassword: string, user: User | undefined): Promise<string | undefined> => {
    const response: any = await apiClient.patch(`/_auth0_api/users/${user?.sub}`, {
      password: newPassword
    })
    return response.data
  }
}
