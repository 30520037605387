import React, { useEffect, useState } from 'react'
import SidebarLayout from '../components/SidebarLayout'

function Calendar() {
  return (
    <>
      <SidebarLayout>
        <h1>todo: Calendar page here</h1>
      </SidebarLayout>
    </>
  )
}
export default Calendar
