import { useAuth0 } from '@auth0/auth0-react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  Input,
  FilledInput,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  FormControl,
  InputAdornment,
  CircularProgress
} from '@mui/material'
import useAccessToken from '../hooks/useAccessToken'
import SidebarLayout from '../components/SidebarLayout'
import IconButton from '@mui/material/IconButton'
import { Visibility, VisibilityOff, Send as SendIcon } from '@mui/icons-material'
import axios from 'axios'
import { styled } from '@mui/system'
import Button, { buttonClasses } from '@mui/base/Button'
const blue = {
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2'
}
const CustomButton = styled(Button)`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 12px 24px;
  border-radius: 12px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${blue[600]};
  }

  &.${buttonClasses.active} {
    background-color: ${blue[700]};
  }

  &.${buttonClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
function Item(props: any) {
  return (
    <div
      style={{
        border: 'thin red solid',
        background: 'white'
      }}>
      {props.children}
    </div>
  )
}
function HomePage() {
  const { logout, user, getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0()
  const [currentBreakpoint, setCurrentBreakpoint] = useState('')
  const accessToken = useAccessToken()
  const [query, setQuery] = useState('when is the breakeven going to happen?')
  const [loading, setLoading] = useState(false)
  const [answers, setAnswers] = useState<any>([
    // {
    //   html: '<h1>hello this is an example answer</h1>'
    // }
  ])
  const [sources, setSources] = useState<any>([
    // {
    //   metadata: {
    //     source: './Phase_II_Blueprint/Business Case/Horizons BC - feedback.docx'
    //   },
    //   page_content: 'hello this is an example source'
    // }
  ])
  async function clickSearch() {
    setLoading(true)
    const apiUrl = 'https://cron.testhere.link/query'
    const resp: any = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query
      }
    })

    setAnswers([
      {
        html: `<h1>${resp.data.answer}</h1>`
      }
    ])
    setSources(resp.data.sources)
    setLoading(false)
  }

  return (
    <>
      <SidebarLayout>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Item>
              {answers.map((x: any) => (
                <div dangerouslySetInnerHTML={{ __html: x.html }}></div>
              ))}
              <br />
              <hr />
              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <InputLabel htmlFor="input-query">Send a message.</InputLabel>
                <FilledInput
                  id="input-query"
                  value={query}
                  onChange={(e: any) => setQuery(e.target.value)}
                  multiline
                  rows={12}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={clickSearch} onMouseDown={e => e.preventDefault()}>
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {loading && <CircularProgress />}

              {/*<Button variant="contained" onClick={clickSearch}>*/}
              {/*  I'm feeling lucky!*/}
              {/*</Button>*/}
            </Item>
          </Grid>
          <Grid item xs={true}>
            <Item>
              {sources.map((x: any) => (
                <div
                  style={{
                    border: 'thin blue solid',
                    margin: 10
                  }}>
                  <CustomButton
                    onClick={() => {
                      window.location = x.metadata.Path
                    }}>
                    {x.metadata.Path}
                  </CustomButton>
                  <p>{x.page_content}</p>
                </div>
              ))}
            </Item>
          </Grid>
        </Grid>
      </SidebarLayout>
    </>
  )
}

export default HomePage
