import React, { ReactNode, ReactElement } from 'react'
import { AppBar, Box, Drawer, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import '../custom.css'
import SidebarContent from './SidebarContent'

interface Props {
  children: React.ReactNode
  elementType?: keyof JSX.IntrinsicElements
}

export default function SidebarLayout({ children, elementType: ElementType = 'main' }: Props): JSX.Element {
  const drawerWidth = 240
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const container = window.document.body

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            display: { sm: 'none' },
            background: 'transparent',
            boxShadow: 'none'
          }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: 'none' },
                background: '#eeeeee',
                '&:hover': {
                  backgroundColor: '#dddddd'
                }
              }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/* background */}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
          // className="radial-gradient-bg"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* mobile */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
            }}>
            <SidebarContent />
          </Drawer>
          {/* desktop */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
            }}
            open>
            <SidebarContent />
          </Drawer>
        </Box>

        <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          {/* <Toolbar /> */}
          <ElementType className="relative flex h-screen grow overflow-auto">{children}</ElementType>
        </Box>
      </Box>
    </>
    // <div className="flex">
    //   <Sidebar></Sidebar>
    //   <ElementType className="flex grow relative h-screen overflow-auto bg-purple-950">{children}</ElementType>
    // </div>
  )
}
