import { DropdownMenu } from 'components/DropdownMenu'
import Accordion from '../../../components/Accordion'
import Button from '../../../components/Button'
import SidebarLayout from '../../../components/SidebarLayout'
import LineChart from 'components/LineChart'


function Support() {
  let menu = [
    {
      title: `How is this site different to Canvas? Why do I need both?`,
      answer: `We have built Learner Hub to give you a user-friendly dashboard summarising key information on your course progress and upcoming dates, along with the ability to read and reply to discussions without logging-in to Canvas.`
    },
    {
      title: `What does the “You have completed XX% of the course” graph mean?`,
      answer: `We designed this graph to help signpost your progress through the course. The calculation is currently based on the percentage of activities (topics, discussions, quizzes or assignments) you have completed in Canvas.`
    },
    {
      title: `When will I get my digital badge?`,
      answer: `If you successfully complete a course and meet all requirements (these might include attendance, grades and assignments) you will be issued with a badge within 5 working days of your course end date.`
    },
    {
      title: `Who do I contact if I need help with course content or an assignment?`,
      answer: `You can ask directly on the discussion forum via either Learner Hub or Canvas. These forums are monitored by subject-matter experts and support teams.
    You can also raise questions in any scheduled live sessions with experts or coaches - schedules vary depending on your course - or reach out to support@mentem.co`
    },
    {
      title: `Who do I contact if I need to request and extension or a leave of absence?`,
      answer:
        'Usually this will be a support mailbox at Mentem and/or your organisation - the details and procedure vary by course. You can check directly in the “support” document on your Canvas course homepage.'
    },
    {
      title: `Where can I share any issues I encounter or feedback I have to improve Learner Hub?`,
      answer:
        'You can email any feedback or bug reports to support@mentem.co and we’ll pass these on to our product team - your feedback is really valuable to us!'
    }
  ]

    //generate dummyData for LineChart
    const dataPoints =  [
        { x: '1', y: 1 },
        { x: '2', y: 2 },
        { x: '3', y: 3 },
        { x: '4', y: 4 },
        { x: '5', y: 5 },
        { x: '6', y: 6 },

      ]


  return (
    <>
      <SidebarLayout>
        <div className="flex w-[80%] max-w-[1920px] flex-col items-center">
          <div className="mx-auto mt-[5%] flex w-[70%] justify-center ">
            <div className="w-full overflow-hidden rounded-[30px] border py-[42px] shadow-2xl">
              <div className="h-[60vh] w-full overflow-y-scroll px-[80px]">
                {menu.map((item, index) => {
                  return (
                    <Accordion title={item.title} key={index} index={index}>
                      <p className="text-[19px] font-bold">{item.answer}</p>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
          <Button classes="py-[10px] px-[30px] w-fit rounded-[30px] text-[26px] font-bold mt-[61px]">
            Contact client support
          </Button>
          <DropdownMenu items={['item 1', 'item 2', 'item 3']} />
          <LineChart title={''} xAxisTitle={''} yAxisTitle={''} dataPoints={dataPoints}/>
        </div>
      </SidebarLayout>
    </>
  )
}

export default Support
