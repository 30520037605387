// components/Accordion.js
import React, { useState } from 'react'
import { ReactComponent as MinusLogo } from '../svgs/minus.svg'
import { ReactComponent as PlusLogo } from '../svgs/plus.svg'

// Define the TypeScript type for the Accordion props
type AccordionProps = {
  title: string;
  children: React.ReactNode;
  index: number;
};


const Accordion = ({ title, children, index }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-t border-black ">
      <button className= {isOpen ? `w-full p-4 flex justify-between items-center border-b border-black `:`w-full p-4 flex justify-between items-center `} onClick={() => setIsOpen(!isOpen)}>
        <span className={isOpen ? `font-bold text-[26px] text-left text-[#BA61FF]  `:`font-bold text-[26px] text-left `}>
          {index + 1}. {title}
        </span>
        <span className="text-xl font-bold">
          {isOpen ? (
            <MinusLogo/>
          ) : (
           <PlusLogo/>
          )}
        </span>
      </button>
      {isOpen && <div className="py-[36px] px-[16px]">{children}</div>}
    </div>
  )
}

export default Accordion