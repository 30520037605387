import { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth0 } from '@auth0/auth0-react'
import { toast, ToastContainer } from 'react-toastify'
import { ReactComponent as Letter } from '../../svgs/letter.svg'
import Input from '../../components/Input'

function ForgotPassword() {
  const [inputEmail, setInputEmail] = useState('')
  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`
    const body = JSON.stringify({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email: inputEmail,
      username: inputEmail,
      connection: 'Username-Password-Authentication'
    })

    try {
      const response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body
      })

      const stream = response.body as ReadableStream<Uint8Array>
      const reader = stream.getReader()
      let data = ''
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        data += new TextDecoder().decode(value)
      }
      setInputEmail('')
      toast.success(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="flex w-full flex-col items-center lg:h-screen lg:flex-row">
      {/*<ToastContainer autoClose={5000} />*/}
      <div className="bg-white-800 text-white lg:inline lg:basis-1/2 ">
        <div className="flex items-center justify-center lg:h-full ">
          <img src="/images/mentem-logo.png" alt="Mentem logo" className="ml-auto" />
        </div>
      </div>
      <div className="flex w-full pb-10 lg:mr-[25px] lg:h-full lg:basis-1/2">
        <form className="mb-auto ml-auto mr-auto w-11/12 lg:my-auto lg:ml-[135px] lg:w-[60%] xl:w-1/2">
          <h1 className="text-[40px] font-bold text-[#000000]" data-testid="title">
            Forgot Password
          </h1>
          <div className="">
            <div className="mt-[40px] flex items-center rounded-[15px] border border-[#B9B9B9] pl-[20px] ">
              <Letter />
              <Input
                type="text"
                onChange={e => setInputEmail(e.target.value)}
                placeholder="Email"
                required
                classes="w-full rounded-r-[15px] text-[20px] ml-3 py-[0.32rem]"
                inputProps={{ id: 'email', name: 'email', value: inputEmail, 'data-testid': 'input form' }}
              />
            </div>
          </div>
          <button
            onClick={handleSubmit}
            type="submit"
            className="mt-[26px] h-[45px] w-full rounded-[30px] bg-[#BD69FE] text-[26px] font-bold text-white">
            RESET PASSWORD
          </button>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
