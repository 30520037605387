import { useEffect, useState } from 'react'
import DiscussionBoard from './../../../components/DiscussionBoard'
import Loading from './../../../components/Loading'
import { DiscussionAPI } from '../../../apis/DiscussionAPI'
import { TopicType } from '../../../apis/types/discussion.type'
import useAccessToken from '../../../hooks/useAccessToken'
import SidebarLayout from '../../../components/SidebarLayout'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

export default function Discussions() {
  const [allTopics, setAllTopics] = useState<TopicType[] | undefined>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentTopic, setCurrentTopic] = useState<TopicType | undefined>()
  const accessToken = useAccessToken()
  const { logout, user, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (accessToken) {
      axios
        .get(`${process.env.REACT_APP_INSIGHT_API_URL}/canvas/user-token`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(resp => {
          // if user does not have canvas token then redirect him to get one
          if (!resp.data.canvasAccessToken) {
            const canvasHome = process.env.REACT_APP_CANVAS_URL
            const clientId = process.env.REACT_APP_CANVAS_CLIENT_ID
            const redirectUri = `${process.env.REACT_APP_INSIGHT_API_URL}/canvas/user-token/redirect`
            const state = `${user?.sub},${window.location.href}`
            window.location.href = `${canvasHome}/login/oauth2/auth?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${state}`
          }
        })

      setIsLoading(true)
      DiscussionAPI.getData()
        .then(topics => {
          setCurrentTopic(topics?.length ? topics[0] : undefined)
          setAllTopics(topics)
          setIsLoading(false)
        })
        .catch(reason => {
          if (reason.response?.data?.errors?.[0]?.message === 'Invalid access token.') {
            const canvasHome = process.env.REACT_APP_CANVAS_URL
            const clientId = process.env.REACT_APP_CANVAS_CLIENT_ID
            const redirectUri = `${process.env.REACT_APP_INSIGHT_API_URL}/canvas/user-token/redirect`
            const state = `${user?.sub},${window.location.href}`
            window.location.href = `${canvasHome}/login/oauth2/auth?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&state=${state}`
          } else {
            throw reason
          }
        })
    }
  }, [accessToken])

  const renderAllTopics = () => {
    return allTopics?.map((topic: TopicType) => (
      <li
        key={topic.id}
        onClick={() => setCurrentTopic(topic)}
        className={` ${
          topic.id === currentTopic?.id ? 'bg-[#BA61FF] text-white' : ''
        } flex h-[90px] w-full cursor-pointer items-center pl-4 text-[22px] font-bold hover:bg-[#BA61FF] hover:text-white`}>
        {topic.title}
      </li>
    ))
  }

  return (
    <>
      <SidebarLayout>
        <div className="flex w-full">
          <div className="w-296 flex h-screen w-[296px] justify-center bg-gray-100 pt-[10%]">
            <div className="mr-[10px] h-[80%] overflow-y-auto">
              <ul className="mr-[10px] list-inside">{!isLoading ? renderAllTopics() : <Loading />}</ul>
            </div>
          </div>
          {!isLoading && currentTopic ? <DiscussionBoard topic={currentTopic} /> : <Loading />}
        </div>
      </SidebarLayout>
    </>
  )
}
