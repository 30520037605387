import React, { useEffect, useRef, useState } from 'react'
import Loading from './Loading'
import ReplyField from './ReplyField'
import { ReactComponent as TopicDiscussions } from '../svgs/topic-discussion.svg'
import { ReactComponent as Reply } from '../svgs/reply.svg'
import { ReactComponent as Close } from '../svgs/close.svg'
import { DiscussionAPI } from '../apis/DiscussionAPI'
import { DiscussionBoardPropsType, SingleReplyType } from '../apis/types/discussion.type'
import useAccessToken from '../hooks/useAccessToken'
import axios from 'axios'
import { toast } from 'react-toastify'
import ToastMessage from './ToastMessage'
import ToastAlert from './ToastAlert'

export default function DiscussionBoard(props: DiscussionBoardPropsType) {
  const [allReplies, setAllReplies] = useState<SingleReplyType[] | undefined>([])
  const [allParticipants, setAllParticipants] = useState<any>([])
  const [currentThread, setCurrentThread] = useState<SingleReplyType[]>([])
  const [currentReply, setCurrentReply] = useState<SingleReplyType>()
  const [topic, setTopic] = useState<{ id: number; title: string; message: string }>(props.topic)
  const [isRepliesLoading, setIsRepliesLoading] = useState<boolean>(false)
  const [disablePosting, setDisablePosting] = useState<boolean>(false)
  const accessToken = useAccessToken()

  useEffect(() => {
    if (accessToken) {
      DiscussionAPI.getThreadData(topic?.id)
        .then((resp: any) => {
          setIsRepliesLoading(true)
          const replies = resp.view.map((reply: any) => {
            reply.user = resp.participants.find((user: any) => reply.user_id === user.id)
            reply.replies?.map((x: any) => {
              return (x.user = resp.participants.find((user: any) => x.user_id === user.id))
            })
            return reply
          })
          setAllReplies(replies)
          setIsRepliesLoading(false)
        })
        .catch(error => {
          if (error.response?.data === 'require_initial_post') {
            setIsRepliesLoading(false)
            setAllReplies([])
            toast(
              <ToastAlert>
                <p>You do not have required permissions to view topic replies.</p>
              </ToastAlert>
            )
          } else {
            throw error
          }
        })
    }
  }, [topic, accessToken])
  const postReply = async (text: string) => {
    if (!text) {
      return
    }
    if (disablePosting) {
      return
    }
    setDisablePosting(true)
    const resp = await axios.post(
      `${process.env.REACT_APP_INSIGHT_API_URL}/_canvas_api/courses/${process.env.REACT_APP_COURSE_ID}/discussion_topics/${topic.id}/entries`,
      {
        message: text
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    )
    setAllReplies([resp.data, ...(allReplies || [])])

    setDisablePosting(false)
  }

  async function postReplyReply(text: string) {
    if (!text) {
      return
    }
    if (disablePosting) {
      return
    }
    setDisablePosting(true)
    const resp = await axios.post(
      `${process.env.REACT_APP_INSIGHT_API_URL}/_canvas_api/courses/${process.env.REACT_APP_COURSE_ID}/discussion_topics/${topic.id}/entries/${currentReply?.id}/replies`,
      {
        message: text
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    )
    setCurrentThread([resp.data, ...(currentThread || [])])

    setDisablePosting(false)
  }

  if (props?.topic?.id !== topic?.id) {
    setIsRepliesLoading(true)
    setTopic(props.topic)
    setIsRepliesLoading(false)
  }
  function fetchReply(reply: SingleReplyType) {
    setCurrentThread(reply.replies ? reply.replies : [])
    setCurrentReply(reply)
  }

  const listAllReplies = () => {
    return allReplies
      ?.sort(
        (a: SingleReplyType, b: SingleReplyType) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      )
      .map((reply: SingleReplyType, i) => {
        return (
          <div
            onClick={() => {
              fetchReply(reply)
            }}
            className={`relative flex p-[15px] hover:bg-gray-200 ${reply.replies?.length > 0 ? 'bg-gray-200' : ''}`}
            key={`reply-${i}`}>
            <img src={reply.user.avatar_image_url} className="mr-[15px]" style={{ height: '50px' }} />
            <div>
              <div className="flex items-center gap-1">
                <p className="mr-[2px] text-[12px] font-bold">{reply.user.display_name}</p>
                <p className="text-[10px] text-[#5F5656]">{reply.updated_at}</p>
              </div>
              <div className="text-[12px] font-medium">
                <div dangerouslySetInnerHTML={{ __html: reply.message }}></div>
              </div>
            </div>
            {reply.replies?.length > 0 && (
              <div className="absolute right-0 top-0 block cursor-pointer p-2">
                <Reply />
              </div>
            )}
          </div>
        )
      })
  }

  const listCurrentThread = () => {
    return currentThread
      .sort(
        (a: SingleReplyType, b: SingleReplyType) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      )
      .map((reply: SingleReplyType, i) => {
        return (
          <div className="group relative flex flex-wrap p-[15px] hover:bg-gray-200" key={`reply-${i}`}>
            <img src={reply.user.avatar_image_url} className="mr-[15px]" style={{ height: '50px' }} />
            <div>
              <div className="flex items-center gap-1">
                <p className="mr-[2px] text-[12px] font-bold">{reply.user.display_name}</p>
                <p className="text-[10px] text-[#5F5656]">{reply.updated_at}</p>
              </div>
              <div className="text-[12px] font-medium">
                <div dangerouslySetInnerHTML={{ __html: reply.message }}></div>
              </div>
            </div>
          </div>
        )
      })
  }

  return (
    <>
      <div className="flex h-[100vh] w-full">
        <div
          className={`${
            currentReply ? 'w-[60%] pl-[5%] pr-[5%]' : 'ml-[3%] w-[100%] pr-[10%]'
          } max-h-[90vh] overflow-y-auto pb-[5%] pt-[10%]`}>
          <h1 className="text-[31px] font-bold text-[#000000]">Discussion board</h1>
          <div className="mt-[34px] flex">
            <TopicDiscussions />
            <h2 className="ml-[10px] text-[21px] font-bold text-[#000000]">Topic With Discussions</h2>
          </div>
          {topic ? (
            <>
              <div className="mt-[35px] flex">
                <div className="mr-[8px] text-[21px] font-bold ">{topic.title}</div>
                <h2 className="text-[21px] font-bold text-[#BD69FE]">Organisational Charts & Roles</h2>
              </div>
              <div className="mt-[30px] text-[18px] text-[#121419]">
                <div dangerouslySetInnerHTML={{ __html: topic.message }}></div>
              </div>
            </>
          ) : (
            ''
          )}
          <div className="mt-[30px] border-t border-black pt-[30px]">
            {disablePosting && <Loading />}
            {isRepliesLoading ? <Loading /> : listAllReplies()}
          </div>

          <ReplyField postReply={postReply} />
        </div>
        <div
          className={`${currentReply ? 'w-[40%] border-l-2 pl-[2.5%] pr-[2.5%]' : 'hidden'} overflow-y-auto pt-[10%]`}>
          <div className="flex w-full items-center justify-between">
            <h2 className="text-[21px] font-bold text-[#000000]">Thread</h2>
            <Close
              className="cursor-pointer"
              onClick={() => {
                setCurrentReply(undefined)
              }}
            />
          </div>
          <div className="group flex p-[15px] hover:bg-gray-200">
            <img src={currentReply?.user.avatar_image_url} className="mr-[15px]" style={{ height: '50px' }} />
            <div>
              <div className="flex items-center gap-1">
                <p className="mr-[2px] text-[12px] font-bold">{currentReply?.user.display_name}</p>
                <p className="text-[10px] text-[#5F5656]">{currentReply?.updated_at}</p>
              </div>
              <div className="text-[12px] font-medium">
                <div dangerouslySetInnerHTML={{ __html: currentReply?.message as string }}></div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            {' '}
            {currentThread.length} replies &nbsp;{' '}
            <p className="h-[2px] w-[80%] border-t-[1px] border-solid border-black"></p>
          </div>
          <div className="mt-[30px]">{listCurrentThread()}</div>
          <ReplyField postReply={postReplyReply} />
        </div>
      </div>
    </>
  )
}
