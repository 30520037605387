// create a line chart component that takes in data and renders a line chart
// data includes chart title, x-axis title, y-axis title, and data points
// data points are an array of objects with x and y values

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
  import { Chart } from 'react-chartjs-2'
  import withFadeIn from './AnimatedContainer';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  ) 


interface DataPoint {
  x: string;
  y: number;
}

interface LineChartProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  dataPoints: DataPoint[];
}

const LineChart: React.FC<LineChartProps> = ({
  title,
  xAxisTitle,
  yAxisTitle,
  dataPoints,
}) => {
  const chartData = {
    labels: dataPoints.map((point) => point.x),
    datasets: [
      {
        label: yAxisTitle,
        data: dataPoints.map((point) => point.y),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisTitle,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisTitle,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default LineChart;//withFadeIn(LineChart);
