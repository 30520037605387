import apiClient from './configs/AxiosClient'
import { SingleReplyType, TopicType } from './types/discussion.type'

export const DiscussionAPI = {
  getData: async (): Promise<TopicType[] | undefined> => {
    const url = `${process.env.REACT_APP_INSIGHT_API_URL}/_canvas_api/courses/${process.env.REACT_APP_COURSE_ID}/discussion_topics`
    const response: any = await apiClient.get(url)
    return response.data
  },
  getThreadData: async (topicId: number): Promise<any> => {
    const url = `${process.env.REACT_APP_INSIGHT_API_URL}/_canvas_api/courses/${process.env.REACT_APP_COURSE_ID}/discussion_topics/${topicId}/view?include_new_entries=1&include_enrollment_state=1&include_context_card_info=1`
    const response: any = await apiClient.get(url)
    return response.data
  }
}
