type Props = {
  classes?: string
  children?: React.ReactNode
  onClick?: (value: any) => void
  buttonProps?: {}
}

export default function Button(props: Props) {
  const { classes, children, onClick, buttonProps } = props
return (
  <button
    className={`${classes} bg-[#BA61FF] text-white`}
    onClick={onClick}
    {...buttonProps}
  >
    {children}
  </button>
)
}
