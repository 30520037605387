import React, { useEffect, useState } from 'react'
import SidebarLayout from '../../components/SidebarLayout'

function Dashboard() {
  return (
    <>
      <SidebarLayout>
      <h1>todo: dashboard here</h1>
      </SidebarLayout>
    </>
  )
}
export default Dashboard
