import { Routes, Route, Link } from 'react-router-dom'
import './App.css'
import LoginPage from './pages/login/login'
import Dashboard from './pages/dashboard/dashboard'
import ForgotPassword from './pages/forgot-password/forgot-password'
import SettingProfile from './pages/SettingProfile'
import HomePage from './pages/home'
import AnalyticsPage from './pages/analytics'
import CalendarPage from './pages/calendar'
import Discussions from './pages/dashboard/discussions/discussions'
import { useAuth0 } from '@auth0/auth0-react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import Support from './pages/dashboard/support/support'
import { AxiosClient } from './apis/configs/AxiosClient'
import { DataProvider } from './context/DataProvider'
import { ToastContainer } from 'react-toastify'
import Sidebar from './components/SidebarContent'

// @ts-ignore
const ProtectedRoute = ({ component, ...args }) => {
  const { isLoading, error } = useAuth0()
  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }
  const Component = withAuthenticationRequired(component, args)
  return <Component />
}

function App() {
  const { user, isAuthenticated } = useAuth0()


  return (
    <>
      <AxiosClient>
        <DataProvider>
          <div className="App">
            <ToastContainer />

            <div>
              {/* {user && (
                <div
                  style={{
                    position: 'absolute',
                    right: 0
                  }}>
                  <img src={user.picture} alt={user.name} />
                  <p>name: {user.name}</p>
                  <p>email: {user.email}</p>
                  <p>nickname: {user.nickname}</p>
                </div>
              )} */}
              
              <Routes>
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />

                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/support" element={<Support />} />

                {/*<Route*/}
                {/*  path="/*"*/}
                {/*  element={*/}
                {/*    <Layout>*/}
                {/*      <Routes>*/}
                {/*        <Route path="/dashboard" element={<Dashboard />} />*/}
                {/*        <Route path="/setting-profile" element={<SettingProfile />} />*/}
                {/*        <Route path="/discussions" element={<Discussions />} />*/}
                {/*        <Route path="/support" element={<Support />} />*/}
                {/*      </Routes>*/}
                {/*    </Layout>*/}
                {/*  }*/}
                {/*/>*/}

                {/* <Route path="/x/login" element={<ProtectedRoute component={LoginPage} />} /> */}
                {/* <Route path="/x/forgot-password" element={<ProtectedRoute component={ForgotPassword} />} /> */}
                <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                <Route path="/setting-profile" element={<ProtectedRoute component={SettingProfile} />} />
                <Route path="/discussions" element={<ProtectedRoute component={Discussions} />} />
                <Route path="/analytics" element={<ProtectedRoute component={AnalyticsPage} />} />
                <Route path="/calendar" element={<ProtectedRoute component={CalendarPage} />} />
                <Route path="/support" element={<ProtectedRoute component={Support} />} />
                <Route path="/" element={<ProtectedRoute component={HomePage} />} />
              </Routes>
            </div>
          </div>
        </DataProvider>
      </AxiosClient>
    </>
  )
}

export default App
