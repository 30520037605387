import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthAPI } from '../apis/AuthAPI'
import { toast } from 'react-toastify'
import ToastAlert from './ToastAlert'
import ToastSuccess from './ToastSuccess'
import Input from './Input'
import Button from './Button'
import useAccessToken from '../hooks/useAccessToken'

interface ChangePasswordModalProps {
  isOpen: boolean
  onClose: () => void
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth0()
  const accessToken = useAccessToken()
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleClick = async () => {
    if (newPassword !== confirmPassword) {
      toast(
        <ToastAlert>
          <p>Passwords do not match</p>
        </ToastAlert>
      )
      return
    }

    const result = await AuthAPI.changePassword(newPassword, user)

    if (result === 'success') {
      toast(
        <ToastSuccess>
          <p>Password changed</p>
        </ToastSuccess>
      )
      onClose()
    } else if (result === 'error') {
      toast(
        <ToastAlert>
          <p>Something went wrong</p>
        </ToastAlert>
      )
    } else {
      <ToastAlert>
        <p>{result}</p>
      </ToastAlert>
    }
  }

  return (
    <div className={`fixed inset-0 z-10 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
      <div className="flex min-h-screen items-center justify-center">
        <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
        <div className="z-50 overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="flex justify-between p-4">
            <p className="text-lg font-bold">Change Password</p>
            <Button
              onClick={onClose}
              classes="float-right text-lg font-bold !bg-white !text-[black]"
              buttonProps={{ form: 'profile-form' }}>
              X
            </Button>
          </div>
          <div className="p-4">
            <div className="mb-4">
              <label className="mb-2 block font-bold text-gray-700">New password</label>
              <Input
                classes="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                required
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="mb-2 block font-bold text-gray-700">Confirm new password</label>
              <Input
                classes="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                required
                value={confirmPassword}
                onChange={event => setConfirmPassword(event.target.value)}
              />
            </div>
          </div>
          <div className="p-4">
            <Button
              onClick={handleClick}
              classes="text-[21px] w-[81px] h-[48px] rounded-[8px]"
              buttonProps={{ form: 'profile-form' }}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordModal
