import { createContext, useContext, useState, ReactNode } from 'react'

interface DataContextProps {
  // clients: Organisation[] | undefined | null
  // setClients: (clients: Organisation[] | undefined | null) => void
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = ():DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}
export const DataProvider = ({ children }: Props) => {
  // const [clients, setClients] = useState<Organisation[] | undefined | null>(undefined)

  const providerValue = {
    // clients,
    // setClients
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

