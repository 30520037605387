import React, { useState, useRef, useEffect } from 'react';

interface DropdownMenuProps {
  items: string[];
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectItem = (item: string) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  return (
    <div className="dropdown relative" ref={dropdownRef}>
      <div
        className="dropdown-title cursor-pointer bg-purple-500 rounded-2xl py-2 px-4 text-white"
        onClick={toggleDropdown}
      >
        {selectedItem ? selectedItem : 'Select an item'}
      </div>
      {isOpen && (
        <ul className="dropdown-list absolute top-full left-0 bg-white rounded-lg shadow py-2 px-4">
          {items.map((item, index) => (
            <li
              key={index}
              className="cursor-pointer hover:bg-gray-200 py-1 px-2 rounded"
              onClick={() => selectItem(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
