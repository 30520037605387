import { ReactComponent as At } from '../svgs/at.svg'
import { ReactComponent as Attach } from '../svgs/attach.svg'
import { ReactComponent as Send } from '../svgs/send.svg'
import axios from 'axios'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { useState, useRef } from 'react'

export default function ReplyField(props: any) {
  const { logout, user, getAccessTokenSilently } = useAuth0()
  const [text, setText] = useState('')
  const buttonRef = useRef(null)
  const btnClick = async () => {
    await props.postReply(text)
    setText('')
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      btnClick()
    }
  }

  return (
    <div className="mt-[46px] rounded-[15px] border border-[#B5B5BE] p-4">
      <textarea
        value={text}
        onChange={event => setText(event.target.value)}
        onKeyDown={handleKeyDown}
        className=" mb-2 w-full resize-none p-2 focus:outline-none"
        placeholder="Ask a question or post an update "></textarea>
      <div className="flex justify-between">
        <div className="flex">
          <At className="mr-[10px]" />
          <Attach />
        </div>

        <Send ref={buttonRef} onClick={btnClick} />
      </div>
    </div>
  )
}
