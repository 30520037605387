type Props = {
  type?: string
  placeholder?: string
  required?: boolean
  classes?: string
  onChange?: (value: any) => void
  inputProps?: {}
  value?: any
  style?: any
}

export default function Input(props: Props) {
  const { type, placeholder, required, inputProps, classes, onChange, value, style } = props
  return (
    <input
      {...inputProps}
      style={style}
      type={type}
      className={`${classes} block px-3 leading-[1.6] text-black outline-none transition-all duration-200 ease-linear focus:ring-0 focus:placeholder:opacity-100`}
      placeholder={placeholder}
      value={value}
      required={required}
      onChange={onChange}
    />
  )
}

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  required: false,
  classes: '',
  inputProps: {}
}
