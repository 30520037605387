import ToastMessage from './ToastMessage'
import { ReactComponent as CircleCheck } from '../svgs/circle-check.svg'

type Props = {
  children?: React.ReactNode
}

export default function ToastSuccess(props: Props) {
  const { children } = props
  return (
    <ToastMessage>
      <CircleCheck className="mr-[24px]" />
      {children}
    </ToastMessage>
  )
}
