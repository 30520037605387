import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ReactComponent as Letter } from '../../svgs/letter.svg'
import { ReactComponent as Lock } from '../../svgs/lock.svg'
import Input from '../../components/Input'

function LoginPage() {
  let navigate = useNavigate()
  // This variable determines whether password is shown or not
  const [isShown, setIsSHown] = useState(false)

  // This function is called when the checkbox is checked or unchecke
  const togglePassword = () => {
    setIsSHown(isShown => !isShown)
  }

  let mockResponseData = {
    success: {
      code: 200,
      message: 'Request successful.',
      data: 'Login successfully'
    }
  }
  // This function is called when the checkbox is checked or unchecke
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (mockResponseData.success) {
      navigate('/dashboard', { state: { showToast: true, content: mockResponseData.success.data } })
    } else {
      // do something
    }
  }

  return (
    <div className="w-full flex items-center lg:h-screen flex-col lg:flex-row">
      <div className="lg:inline lg:basis-1/2 bg-white-800 text-white ">
        <div className="flex lg:h-full items-center justify-center ">
          <img src="/images/mentem-logo.png" alt="Mentem logo" className="ml-auto" />
        </div>
      </div>
      <div className="flex w-full lg:basis-1/2 lg:h-full lg:mr-[25px] pb-10">
        <form
          className="w-11/12 xl:w-1/2 lg:w-[60%] lg:my-auto lg:ml-[135px] ml-auto mr-auto mb-auto"
          onSubmit={handleSubmit}>
          <h1 className="font-bold text-[#000000] text-[40px]">Welcome!</h1>
          <div>
            <div className="">
              <div className="flex border rounded-[15px] pl-[20px] items-center border-[#B9B9B9] mt-[40px] ">
                <Letter />
                <Input
                  type="text"
                  placeholder="Email"
                  required
                  classes="w-full rounded-r-[15px] text-[20px] ml-3 py-[0.32rem]"
                  inputProps={{ id: 'email', 'data-testid': 'input form' }}
                />
              </div>
            </div>
            <div className="flex border relative rounded-[15px] pl-[20px] mt-[31px] items-center border-[#B9B9B9]">
              <Lock />
              <Input
                type={isShown ? 'text' : 'password'}
                placeholder="Password"
                required
                classes="w-full rounded-r-[15px] text-[20px] ml-3 py-[0.32rem]"
                inputProps={{ id: 'password' }}
              />
              <i
                className={`fa-solid ${
                  isShown ? 'fa-eye' : 'fa-eye-slash'
                } absolute top-[34%] right-[4%] cursor-pointer text-[lightgray]`}
                id="eye"
                onClick={togglePassword}></i>
            </div>
          </div>
          <div className="flex justify-between w-full mt-[26px]">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="save-account"
                name="save-account"
                className="mr-[9px] h-[17px] w-[17px] rounded-lg outline-[#BD69FE]"
              />
              <label className="font-medium">Remember Me?</label>
              <br></br>
            </div>
            <button className="text-[#1769FF] underline ">
              <a href="/forgot-password" target="_blank">
                Forgot Password?
              </a>
            </button>
          </div>
          <button
            type="submit"
            className="bg-[#BD69FE] rounded-[30px] h-[45px] text-[26px] font-bold text-white mt-[26px] w-full">
            LOG IN
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
