import { useAuth0 } from '@auth0/auth0-react'
import { ReactComponent as MentemLogo } from '../svgs/mentem-logo.svg'
import { ReactComponent as ArrowLeftIcon } from '../svgs/arrow-left.svg'
import logo from 'images/logo512.png'

import { Link, useLocation } from 'react-router-dom'

export default function SidebarContent() {
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0()

  const menu = [
    { title: `Overview`, icon: 'home', path: '/dashboard' },
    {
      title: 'Analytics',
      icon: 'line-chart',
      path: '/analytics'
    },
    {
      title: 'Calendar',
      icon: 'calendar',
      path: '/calendar'
    },
    {
      title: 'Discussions',
      icon: 'comments',
      path: '/discussions'
    },
    {
      title: 'Settings',
      icon: 'gear',
      path: '/setting-profile'
    },
    {
      title: 'Support',
      icon: 'question',
      path: '/support'
    },
    {
      title: '__DEBUG__',
      icon: 'flask',
      path: '/'
    }
  ]

  let activePathname = window.location.pathname
  return (

    // background
    <div className='radial-gradient-bg h-max '>
      {/* full screen or longer content container */}
      <div className="flex w-full flex-col items-center min-h-screen py-8">
        <div className='h-24 w-24 relative'>
          <img src={logo} alt='logo'/>
        </div>
        
        <div className='h-12'></div>
        <div className='flex flex-col justify-items'>
          {menu.map((item, index) => (
            <Link
              key={index}
              className={` flex h-12 w-fit text-left hover:text-[#BD69FE] items-center ${
                activePathname === item.path ? 'text-[#BD69FE]' : ''
              }`}
              to={item.path}>
              <i className={`fa fa-${item.icon}`}></i>
              <span className="ml-2 text-[20px] font-bold">{item.title}</span>
            </Link>
          ))}
          <div className='h-4'></div>
          <button
            className="float-center w-fit rounded-[30px] bg-[#FFFFFF] py-[10px] px-[30px] text-[12px] font-bold"
            onClick={() =>
              logout({
                logoutParams: { returnTo: window.location.origin }
              })
            }>
            Log out
          </button>
        </div>
      </div>

      {/* </div> */}
      {/* <a className="flex items-center justify-center w-full h-16 mt-auto bg-gray-800 hover:bg-gray-700 hover:text-gray-300" href="#">
			<svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>
			<span className="ml-2 text-sm font-medium">Account</span>
		</a> */}
    </div>
  )
}
