import ToastMessage from './ToastMessage'
import { ReactComponent as CircleAlert } from '../svgs/circle-alert.svg'

type Props = {
  children?: React.ReactNode
}

export default function ToastAlert(props: Props) {
  const { children } = props
  return (
    <ToastMessage>
      <CircleAlert className="mr-[24px]" />
      {children}
    </ToastMessage>
  )
}
